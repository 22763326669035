@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply box-border inline-flex items-center justify-center gap-1 border px-6 text-center text-sm;
  }

  .btn-primary {
    @apply bg-primary text-neutral-50 hover:bg-primary-500 focus-visible:border-neutral-700 focus-visible:bg-primary-400 active:bg-primary-400;
  }

  .btn-secondary {
    @apply bg-secondary-600 text-neutral-50 hover:bg-secondary-500 focus-visible:border-neutral-700 focus-visible:bg-secondary-400 active:bg-secondary-400;
  }

  .btn-outline {
    @apply border-primary-600 text-primary hover:bg-primary-100 focus-visible:border-neutral-700 focus-visible:outline focus-visible:outline-4 focus-visible:outline-primary-200 active:bg-primary-200;
  }

  .btn-ghost {
    @apply border-none text-primary hover:bg-primary-100 focus-visible:border focus-visible:border-solid focus-visible:border-neutral-700 focus-visible:outline focus-visible:outline-4 focus-visible:outline-primary-200 active:bg-primary-200;
  }

  .visually-disabled-bg,
  .visually-disabled-bg:hover,
  .visually-disabled-bg:focus,
  .visually-disabled-bg:active {
    @apply !border-neutral-100 !bg-neutral-100;
  }

  .visually-disabled-text,
  .visually-disabled-text:hover,
  .visually-disabled-text:focus,
  .visually-disabled-text:active {
    @apply !text-neutral-300;
  }
}

html {
  background-color: #ffffff;
}
